import React, { memo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import tw from "twin.macro"
import Image from "gatsby-image"
import ImagePoly from "gatsby-image/withIEPolyfill"
import { AboutPageQuery } from "../../../graphql-types"
import {
  SectionTitle,
  containerTw,
  SectionSubtitle,
} from "../../eletrons/BaseStyles"
import Paragraphs from "../../atoms/Paragraphs/Paragraphs"
import { css } from "@emotion/core"

const styles = {
  container: [containerTw, tw`pb-12`],
  content: tw`md:max-w-3xl`,
  prizesTitle: SectionSubtitle,
  prizesList: tw`pb-6`,
  clientsTitle: SectionSubtitle,
  clientsContainer: [
    tw`flex flex-wrap items-center`,
    css`
      margin-left: -0.75rem;
    `,
  ],
  client: [tw`flex items-center justify-center p-3`],
}

export interface AboutInterface {
  info: string[]
  prizes: string[]
}

function About({ info, prizes }: AboutInterface) {
  const {
    menuJson: { title },
    allClientsJson: { nodes: clients },
    usImage,
  }: AboutPageQuery = useStaticQuery(graphql`
    query AboutPage {
      menuJson(id: { eq: "02" }) {
        title
      }
      usImage: file(relativePath: { eq: "camera-steady.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, maxHeight: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      allClientsJson {
        nodes {
          id
          title
          url
          # file {
          #   childImageSharp {
          #     fluid(maxWidth: 100) {
          #       ...GatsbyImageSharpFluid_tracedSVG
          #     }
          #   }
          # }
        }
      }
    }
  `)
  return (
    <section id="sobre" css={styles.container}>
      <div css={styles.content}>
        <h1 css={SectionTitle}>
          02. <b>{title}</b>
        </h1>
        <Paragraphs content={info} />
      </div>
      {/* <ImagePoly
        objectFit="cover"
        objectPosition="50% 50%"
        fluid={usImage.childImageSharp.fluid}
      /> */}
      <h2 css={styles.prizesTitle}>
        <b>Prêmios e conquistas</b>
      </h2>
      <ul css={styles.prizesList}>
        {prizes.map((p) => (
          <li key={p} dangerouslySetInnerHTML={{ __html: p }} />
        ))}
      </ul>
      <h2 css={styles.clientsTitle}>
        <b>Clientes</b>
      </h2>
      <ul css={styles.clientsContainer}>
        {clients.map(({ id, url, title }) => (
          // <ImagePoly

          //   // fluid={file?.childImageSharp?.fluid}
          //   imgStyle={{ objectFit: "contain" }}
          //   style={{ width: "100px" }}
          // />
          // <img key={id} src={file.childImageSharp.fluid.tracedSVG} />
          <li key={id}>
            <a css={styles.client} href={url} target="__blank" rel="nofollow">
              {title}
            </a>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default memo(About)
