import styled from "@emotion/styled/macro";
import { css } from "@emotion/core";
import { Link } from "gatsby";
import { red } from "../../eletrons/Colors";

export const A = styled.a(() => [
  css`
    transition: 0.05s ease-in-out color;

    &,
    &:hover,
    &:focus {
      text-decoration: none;
    }
    span {
      text-decoration: underline;
    }

    &:hover,
    &:focus {
      outline-color: ${red.toString()};
      span {
        color: ${red.toString()};
      }
    }
  `,
])
