import React from "react"
import tw from "twin.macro"
import { motion, HTMLMotionProps } from "framer-motion"
import MenuItems from "../../molecules/MenuItems/MenuItems"
import Footer from "../../atoms/Footer"
import { Menu } from "../../../interfaces/menu"
import { css } from "@emotion/core"
import { TwinStyled } from "../../../interfaces/styles"

const containerAnimation = {
  initial: {
    opacity: 1,
    height: "0",
    transition: {},
  },
  visible: {
    opacity: 1,
    height: "100vh",
    transition: {
      duration: 0.1,
      when: "beforeChildren",
      staggerChildren: 0.05,
    },
  },
  exit: {
    opacity: 1,
    height: "0%",
    transition: {},
  },
}

const itemAnimation = {
  initial: { opacity: 0, y: -50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.1,
    },
  },
  exit: {
    opacity: 0,
    y: -50,
    transition: {
      duration: 0.1,
    },
  },
}

export interface NavContentInterface {}

interface NavContentInternalInterface
  extends HTMLMotionProps<"nav">,
    NavContentInterface,
    TwinStyled {
  onClickLink: () => void
}

function NavContent({ styles, onClickLink }: NavContentInternalInterface) {
  return (
    <motion.nav
      css={[
        tw`bg-black flex flex-col justify-between items-center`,
        css`
          max-height: -webkit-fill-available;
          max-height: -moz -fill-available;
        `,
        styles,
      ]}
      initial="initial"
      animate="visible"
      exit="exit"
      variants={containerAnimation}
      transition={{ ease: "easeInOut" }}
    >
      <div />
      <MenuItems
        animations={{ item: itemAnimation }}
        liStyle={tw`sm:text-3xl md:text-4xl lg:text-5xl`}
        onClickItem={onClickLink}
      />
      <motion.div
        variants={itemAnimation}
        css={tw`px-6 pb-6 sm:px-12 self-start w-full sm:w-auto`}
      >
        <Footer />
      </motion.div>
    </motion.nav>
  )
}

export default NavContent
