import React, { memo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import tw from "twin.macro"
import { containerTw, SectionTitle } from "../../eletrons/BaseStyles"
import VideosGrid from "../../molecules/VideosGrid/VideosGrid"
import { PortfolioTitleQuery } from "../../../graphql-types"
import Paragraphs from "../../atoms/Paragraphs/Paragraphs"

function Portfolio({ portfolio }: { portfolio: string[] }) {
  const {
    menuJson: { title },
  }: PortfolioTitleQuery = useStaticQuery(graphql`
    query PortfolioTitle {
      menuJson(id: { eq: "01" }) {
        title
      }
    }
  `)

  return (
    <section id="portfolio" css={tw`py-24`}>
      <div css={[containerTw, tw`mb-6`]}>
        <h1 css={SectionTitle}>
          01. <b>{title}</b>
        </h1>
        <div css={tw`md:max-w-3xl mb-16`}>
          <Paragraphs content={portfolio} />
        </div>
        <p css={tw` text-xs`}>Filtre por nossas áreas de atuação</p>
      </div>
      <VideosGrid
        areasStyles={containerTw}
        descriptionStyles={tw`pb-3 pt-10`}
      />
    </section>
  )
}

export default memo(Portfolio)
