import React, { forwardRef, useEffect, memo } from "react"
import { motion, ForwardRefComponent, HTMLMotionProps } from "framer-motion"
import { css } from "@emotion/core"
import tw from "twin.macro"
import MenuItems from "../../molecules/MenuItems/MenuItems"
import placeholder from "../../../assets/images/camera-steady.jpg"
import VideoBackground from "../../atoms/VideoBackground/VideoBackground"
import { BorderLink } from "../../atoms/BorderButton/BorderButton"
import { InvertedLink } from "../../atoms/InvertedButton/InvertedButton"
import Arrow from "../../atoms/SvgImages/Arrow"
import { FooterInterface } from "../../../interfaces/footer"
import SocialList from "../../atoms/SocialList"
import { containerTw } from "../../eletrons/BaseStyles"

const styles = {
  header: [
    tw`flex flex-col justify-between sm:justify-center relative`,
    css`
      min-height: 100vh;
      min-height: -webkit-fill-available;
      min-height: -moz -fill-available;
    `,
  ],
  socialList: tw`hidden absolute top-0 right-0 mr-6 mt-4 sm:flex sm:flex-col`,
  line: [
    tw`hidden absolute sm:block sm:bg-white`,
    css`
      width: 2px;
      top: 35%;
      bottom: 30%;
      right: calc(1.5rem + 18px);
      opacity: 0.2;
    `,
  ],
  content: [containerTw, tw`w-full pt-16 sm:pr-20 sm:py-0`],
  h1: tw`leading-tight my-6 text-4xl text-3xl sm:mt-0 sm:mb-6 sm:text-5xl md:text-6xl`,
  h2: tw`leading-tight text-base max-w-5xl sm:text-2xl sm:mb-8 md:text-3xl lg:text-4xl`,
  spacer: [
    css`
      height: 10vh;
      max-height: 5rem;
      min-height: 2rem;
    `,
    tw`sm:hidden`,
  ],
  reelButton: tw`text-sm sm:text-base sm:mb-12`,
  footer: {
    container: tw`absolute hidden sm:block bottom-0 w-full`,
    content: [containerTw, tw`pb-12 text-sm flex flex-row underline`],
    email: tw`mr-10`,
  },
  ctaMobile: {
    container: tw`absolute flex justify-center bottom-0 inset-x-0 pb-4 sm:hidden`,
    link: tw`p-3 flex justify-center flex-col text-xs`,
    text: tw`mb-3`,
    icon: tw`self-center`,
  },
}

const animations = {
  container: {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.1,
        when: "beforeChildren",
        staggerChildren: 0.05,
      },
    },
  },
  item: {
    hidden: { x: -20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
    },
  },
  video: {
    hidden: {
      clipPath: `inset(0 100% 0 0)`,
      background: "red",
    },
    visible: {
      clipPath: `inset(0 0% 0 0)`,
      background: "black",
      transition: {
        ease: "easeOut",
        duration: 0.5,
      },
    },
  },
}

export interface HeroInterface {
  title: string
  description: string
  video: string
  videoMobile: string
  button: {
    label: string
    link: string
  }
}

interface HeroContainerInterface {
  footer: FooterInterface
}

type Props = HeroInterface & HeroContainerInterface
type Ref =
  | ForwardRefComponent<HTMLElement, HTMLMotionProps<"header">>
  | HTMLHeadingElement

function Hero(
  { button, title, description, video, videoMobile, footer }: Props,
  ref
) {
  return (
    <motion.header
      ref={ref}
      css={styles.header}
      initial="hidden"
      animate="visible"
      variants={animations.container}
    >
      <div css={styles.socialList}>
        <SocialList />
      </div>
      <div css={styles.line} />
      <div css={styles.content}>
        <motion.h1
          variants={animations.item}
          css={styles.h1}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <motion.h2
          variants={animations.item}
          css={styles.h2}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div css={styles.spacer} />
        <BorderLink
          href={button.link}
          target="__blank"
          rel="nofollow"
          css={styles.reelButton}
        >
          {button.label}
        </BorderLink>
        <div>
          <MenuItems
            animations={{ item: animations.item }}
            ulStyle={[
              tw`text-sm hidden sm:inline-block `,
              css`
                min-width: 45%;
              `,
            ]}
          />
        </div>
      </div>
      <div css={styles.footer.container}>
        <div css={styles.footer.content}>
          <a
            href={`mailto:${footer.email}`}
            target="__blank"
            css={styles.footer.email}
          >
            {footer.email}
          </a>
          <a href={`mailto:${footer.phone}`} target="__blank">
            {footer.phone}
          </a>
        </div>
      </div>
      <div css={styles.ctaMobile.container}>
        <InvertedLink css={styles.ctaMobile.link} href="#portfolio">
          <span css={styles.ctaMobile.text}>Saiba mais</span>
          <Arrow
            width="16"
            height="16"
            orientation="down"
            title="Veja o nosso conteúdo"
            css={styles.ctaMobile.icon}
          />
        </InvertedLink>
      </div>
      <VideoBackground
        src={video}
        srcMobile={videoMobile}
        placeholder={placeholder}
        animation={animations.video}
      />
    </motion.header>
  )
}

export default memo(forwardRef<Ref, Props>(Hero))
