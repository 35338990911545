import React from "react"
import IndexTemplate from "../components/templates/IndexTemplate/IndexTemplate"
import content from "../content/index.json"
import SEO from "../components/atoms/SEO/SEO"
import LayoutBase from "../layouts/Base/layout"
import { graphql } from "gatsby"
import { Query } from "../graphql-types"
import video from "../assets/videos/reel-bg.mp4"
import videoMobile from "../assets/videos/reel-bg-mobile.mp4"

const IndexPage = ({
  data: {
    site: {
      siteMetadata: { title, altTitle, description, email, phone },
    },
  },
}: {
  data: Query
}) => (
  <LayoutBase>
    <SEO />
    <IndexTemplate
      hero={{
        ...content.hero,
        title: content.hero.title.replace("%s", altTitle),
        description,
        video,
        videoMobile,
      }}
      footer={{ email, phone }}
      portfolio={content.portfolio}
      about={content.about}
    />
  </LayoutBase>
)

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        altTitle
        description
        email
        phone
      }
    }
  }
`
