import React, { SVGProps } from "react"
import { motion, useAnimation } from "framer-motion"
import { css } from "@emotion/core"

function Logo({
  title,
  ...props
}: { title: string } & Partial<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      css={css`
        transition: 0.2s ease-in-out height;
      `}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1194 301"
      {...props}
    >
      <title>{title}</title>
      <defs>
        <mask id="mask" x="-100" y="-100" width="1294" height="401">
          <rect width="1294" height="401" fill="white" />
          <path
            id="hand-left-mask"
            d="M246.35 241.67c-1.73-10.08-10.14-15.67-23.67-15.67a46.3 46.3 0 00-7.77.62c-7 1-14.11 1.89-21 2.8s-14 1.84-21 2.8h-.21l-.21.05a19.5 19.5 0 01-4.07.38c-7.78 0-17.5-3.74-19.62-21.6-3.39-29.44-12-154.6-13.11-170.22.13-2 .16-8.74-4.41-13.72-3.25-3.55-7.93-5.35-13.91-5.35-10.07 0-14.86 5.28-17.12 9.7a23.18 23.18 0 00-2.37 9.91v.25c1.16 18.72 2.3 37.56 3.41 56.32-3.3-2.87-7.65-4.42-13-4.6h-.14a18.51 18.51 0 00-14.43 6.81c-4.35 5.11-6.34 12.23-5.81 16.33q.15 3.35.28 6.58c-3.21-2.28-7.5-3.42-12.79-3.42h-.13c-6.2.19-11.08 2.29-14.5 6.24-5.58 6.45-5.09 15.61-4.86 18v7.37c-3.41-3.42-8.15-5.14-14.11-5.14-6.21 0-11.26 1.92-15 5.71C.62 158.1.32 167.34.33 169.27l-.56 38.53Q-.6 233.33-1 258.91c-.35 5.26-1.26 30.27 12.75 37.9 5.49 2.81 12.54 4.24 21 4.24a96.31 96.31 0 0022.89-2.93q33.33-5.49 66.64-10.93c32.71-5.36 66.53-10.89 99.68-16.39 2.68-.25 14.27-1.82 20.68-10.28 3.91-5 5.13-11.36 3.71-18.85z"
            fill="black"
            transform="translate(0, 0)"
          />
        </mask>
      </defs>
      <path
        id="title"
        d="M533.87 139.28c-3.52-9.05-11.76-15-22.84-15-15 0-24.87 10.81-24.87 25.68S496 175.64 511 175.64c11.08 0 19.32-5.95 22.84-15H557c-4.46 20.94-22.3 35.27-46 35.27-27.57 0-47-19.46-47-45.95S483.46 104 511 104c23.65 0 41.49 14.33 46 35.27zM623.74 194.15V169h-34.6v25.13h-21.35V142.8c0-24.06 13.64-38.79 38.78-38.79s39.06 14.73 39.06 38.79v51.35zm0-51.35c0-12.17-5.28-18.52-17.3-18.52s-17.3 6.35-17.3 18.52v7.29h34.6zM661.71 194.15v-88.38h34.73c24.06 0 34.73 13.11 34.73 31.08s-10.67 31.22-34.73 31.22H683.6v26.08zm35.14-46.35c9.32 0 12.16-4.6 12.16-11s-2.84-10.8-12.16-10.8H683.6v21.8zM761.71 194.15V126h-24.05v-20.23h70.14V126h-24.19v68.11zM871.59 194.15V169H837v25.13h-21.37V142.8c0-24.06 13.65-38.79 38.79-38.79s39.06 14.73 39.06 38.79v51.35zm0-51.35c0-12.17-5.28-18.52-17.3-18.52S837 130.63 837 142.8v7.29h34.6zM941.18 205.23l4.06-9.46v-.27c-23.93-2.83-40.14-21.21-40.14-45.54 0-26.49 19.46-45.95 47-45.95 23.65 0 41.49 14.33 46 35.27H975c-3.52-9.05-11.76-15-22.84-15-15 0-24.87 10.81-24.87 25.68s9.87 25.68 24.87 25.68c11.08 0 19.32-5.95 22.84-15h23.11c-4.33 20.13-21 34.05-43.25 35.13l-1.89 4.6v.13a9.35 9.35 0 011.76-.13c7 0 12.16 4.73 12.16 11.62 0 8.11-6.08 12.44-15.68 12.44s-15.54-4.19-15.54-11.9h11.49c0 2.44 2 3.25 4 3.25s3.79-.95 3.79-3.65a3.45 3.45 0 00-3.65-3.52 5.52 5.52 0 00-2.44.68zM1064.84 194.15V169h-34.6v25.13h-21.36V142.8c0-24.06 13.65-38.79 38.79-38.79s39.06 14.73 39.06 38.79v51.35zm8.91-117.58c-1.08 14.33-6.62 21.76-16.89 21.76-8.92 0-11.49-5.94-16.76-5.94-2.56 0-4 1.89-4.59 6.08h-13.65c1.08-14.33 6.62-21.76 16.89-21.76 8.92 0 11.49 6 16.76 6 2.57 0 4-1.9 4.6-6.09zm-8.91 66.23c0-12.17-5.28-18.52-17.3-18.52s-17.3 6.35-17.3 18.52v7.29h34.6zM1145.38 195.91c-27.57 0-47-19.46-47-45.95s19.46-45.95 47-45.95 47 19.46 47 46-19.38 45.9-47 45.9zm24.87-45.91c0-14.87-9.87-25.68-24.87-25.68s-24.87 10.81-24.87 25.68 9.87 25.68 24.87 25.68 24.87-10.85 24.87-25.68z"
        fill="#fff"
      />
      <path
        id="hand-right"
        d="M256.3 0c10.3 0 12.57 9.8 12.7 11 5.84 34.35 11.68 73.09 17.53 107.45 1-.14 2-.41 3.07-.55-2.63-13.41-4.82-31.2-7.31-44.62-.21-.7-4.23-14 9.35-17.12a14.26 14.26 0 013.28-.4c9.29 0 14.88 10 15.27 13.55 2.49 14.23 4.53 27.38 7.16 41.75.88-.14 1.61-.41 2.48-.55-1.6-8.08-2.77-17.25-4.38-25.32-.15-.61-3.06-12.18 10.37-14.92a16.73 16.73 0 012.66-.22c13.1 0 15.06 16.07 15.16 16.92 1.9 7.8 3.07 13.68 5 21.62 1 .28 1.9.55 2.92.82-.4-1.07-8-21.35 8.91-24.77a17.1 17.1 0 013.23-.33c12.59 0 15.51 16.11 16 18.4 6.14 25.32 11.84 50.78 18.12 76.24.26 1 5.11 21.08-5.84 30.39-11.83 9.17-24.39 9.58-34 11.36-52.44 7.94-99 17.66-149.72 22.86a30.15 30.15 0 01-5.55.48c-6.8 0-17.47-2.15-18-15.54-.58-16.7 20.37-17.21 21.48-17.24a410.51 410.51 0 0040.81-6.67c13.14-3.43 23.07-12.73 18.11-41.88-4.53-26.56-22.57-141.13-23.52-147.16-.17-.63-2.39-12.55 11.05-15.19A19.73 19.73 0 01256.3 0z"
        fill="#fff"
        mask="url(#mask)"
      />
      <path
        id="hand-left"
        d="M117.39 25.83c15.62 0 14.38 14.31 14.31 15.06.49 7 9.64 140.17 13.15 170.7 2.6 22 16.09 25.13 23.59 25.13a22.06 22.06 0 005-.5c14-1.91 28.05-3.7 42.07-5.61a41.89 41.89 0 017.14-.57c7.35 0 17.95 1.87 19.74 12.34 4.23 22.32-19.64 24.39-20.89 24.5q-83 13.77-166.66 27.38a91.84 91.84 0 01-22 2.84c-6.58 0-13.54-.92-19.15-3.8C1.11 286.46 2.92 260.45 3 259.08q.65-45 1.31-89.79c0-1-.15-19.16 17.53-19.16 19.72 0 15.42 22 15.19 23.13 1-.55 1.9-1.1 2.92-1.65v-27.92c-.11-1-2.19-19.43 15.49-20 15.48 0 14.92 11 14.89 11.63.29 9.45.44 18.89.59 28.47H74c-.87-16.83-1.31-30.8-2-47.63-.72-4.44 3.78-18.77 16.25-18.77 16.61.56 15.64 16.43 15.59 17.26q.89 23.42 1.75 46.82h3.51q-3.5-60-7.16-120c-.04-.81-.04-15.64 15.45-15.64z"
        fill="#fff"
      />
    </svg>
  )
}

export default Logo
