import styled from "@emotion/styled/macro";
import tw from 'twin.macro';
import { css } from "@emotion/core";
import { motion } from "framer-motion";
import { gray } from "../../eletrons/Colors";


export const Background = styled(motion.div)(() => [
  css`
    background: ${gray.toString()};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -100;
    pointer-events: none;
    overflow: hidden;
    opacity: .3;
  `
])

export const Video = styled.video(() => [
  css`
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `
])
