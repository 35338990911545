import React, { memo } from "react"
import tw from "twin.macro"
import SocialList from "./SocialList"
import { Query } from "../../graphql-types"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"
import { TwinStyled } from "../../interfaces/styles"

function Footer({ styles }: TwinStyled) {
  const query: Query = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phone
          email
        }
      }
    }
  `)

  return (
    <footer css={[tw`flex  flex-col`, styles]}>
      <div
        css={[
          tw`flex items-center`,
          css`
            margin-left: -0.5rem;
          `,
        ]}
      >
        <SocialList />
        <hr css={[tw`w-full`]} />
      </div>
      <div css={[tw`flex flex-col text-xs sm:text-sm sm:flex-row underline `]}>
        <a
          href={`mailto:${query?.site?.siteMetadata?.email ?? ""}`}
          css={[tw`break-normal py-3 sm:pb-0 sm:pt-2`]}
        >
          {query?.site?.siteMetadata?.email ?? ""}
        </a>
        <a
          href={`tel:${query?.site?.siteMetadata?.phone ?? ""}`}
          css={[tw`pt-3 sm:p-0 sm:pt-2 sm:pl-6`]}
        >
          {query?.site?.siteMetadata?.phone ?? ""}
        </a>
      </div>
    </footer>
  )
}

export default memo(Footer)
