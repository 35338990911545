import React, { useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AnimatePresence, motion } from "framer-motion"
import tw from "twin.macro"
import { css } from "@emotion/core"
import { VideosGridQuery } from "../../../graphql-types"
import { InvertedButton } from "../../atoms/InvertedButton/InvertedButton"
import { containerTw } from "../../eletrons/BaseStyles"
import VideoItem from "../../atoms/VIdeoItem/VideoItem"
import { TwinStyle } from "../../../interfaces/styles"

type Area = VideosGridQuery["allAreasJson"]["nodes"][0]

const descriptionAnimaiton = {
  initial: { opacity: 0, height: 0 },
  animate: { opacity: 1, height: "auto" },
  exit: { opacity: 0, height: 0 },
}

const inactiveStyle = css`
  opacity: 0.4;
`

const VideosGridGraphql = graphql`
  query VideosGrid {
    allAreasJson(sort: { fields: title }) {
      nodes {
        title
        tag
        description
        id
      }
    }
    allVideosJson(sort: { fields: year }) {
      nodes {
        id
        cliente
        title
        url
        year
        areas {
          tag
          id
          title
        }
        file {
          childImageSharp {
            fluid(maxWidth: 640) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

function VideosGrid({
  areasStyles,
  descriptionStyles,
}: {
  areasStyles?: TwinStyle
  descriptionStyles?: TwinStyle
}) {
  const {
    allAreasJson: { nodes: areas },
    allVideosJson: { nodes: videos },
  }: VideosGridQuery = useStaticQuery(VideosGridGraphql)
  const areasButtonsRef = useRef<HTMLDivElement>()
  const [activeArea, setActiveArea] = useState<Area>()

  const selectArea = (areaTag?: string) => () => {
    setActiveArea(areas.find((area) => area.tag === areaTag))
    /*
    if (areasButtonsRef.current) {
      window.scrollTo(0, areasButtonsRef.current.offsetTop - 60)
    }
    */
  }

  const currentVideos = videos.filter((video) => {
    if (!activeArea) return video
    return activeArea.tag === video.areas.tag
  })

  return (
    <>
      <div css={[tw`mb-12`, areasStyles]} ref={areasButtonsRef}>
        <InvertedButton
          active={!activeArea}
          css={[activeArea && inactiveStyle]}
          onClick={selectArea()}
        >
          Ver todos
        </InvertedButton>
        {areas.map((area) => (
          <InvertedButton
            key={area.tag}
            active={activeArea && area.tag === activeArea.tag}
            onClick={selectArea(area.tag)}
            css={[area.tag !== activeArea?.tag && inactiveStyle]}
          >
            {area.title}
          </InvertedButton>
        ))}
        {/* <AnimatePresence>
          {activeArea && (
            <motion.div
              initial={descriptionAnimaiton.initial}
              animate={descriptionAnimaiton.animate}
              exit={descriptionAnimaiton.exit}
              transition={{ ease: "easeInOut" }}
              css={[tw`flex`]}
            >
              <p css={[descriptionStyles]}>{activeArea.description}</p>
            </motion.div>
          )}
        </AnimatePresence> */}
      </div>
      <div css={[tw`flex flex-wrap`]}>
        {currentVideos.length ? (
          currentVideos.map((video) => (
            <VideoItem
              {...video}
              key={video.id}
              styles={[tw`w-1/2 sm:w-1/3 md:w-1/4 xl:w-1/5`]}
            />
          ))
        ) : (
          <p css={[tw`text-sm w-full`, containerTw]}>
            Ainda não colocamos os nossos trabalhos de{" "}
            {activeArea.title.toLowerCase()} aqui
          </p>
        )}
      </div>
    </>
  )
}

export default VideosGrid
