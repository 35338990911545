import React, { useState, HTMLProps } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { red } from "../../eletrons/Colors"

interface HamburgerIconInterface {
  active?: boolean
}

const HamburgerInner = styled.span`
  display: block;
  margin-top: -2px;
  top: 2px;

  &,
  &::before,
  &::after {
    width: 40px;
    height: 4px;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: -10px;
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s;
  }

  &::after {
    bottom: -10px;
    top: 20px;
  }
`
const HamburgerBox = styled.span`
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
`
const HamburgerButton = styled.button`
  padding: 7px 8px 8px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &.active {
    ${HamburgerInner}, ${HamburgerInner}::before, ${HamburgerInner}::after {
      background-color: ${red.toString()};
    }
    ${HamburgerInner} {
      transform: translate3d(0, 10px, 0) rotate(45deg);
    }
    ${HamburgerInner}::before {
      transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0;
    }
    ${HamburgerInner}::after {
      transform: translate3d(0, -20px, 0) rotate(-90deg);
    }
  }
  &:hover, &.active:hover {
    opacity: 0.7;
  }

`

function HamburgerIcon({
  active,
  styles,
  ...props
}: HamburgerIconInterface & { styles: any[] } & Partial<
    HTMLProps<HTMLButtonElement>
  >) {
  return (
    <HamburgerButton
      {...props}
      className={active ? "active" : ""}
      css={[styles]}
      type="button"
    >
      <HamburgerBox>
        <HamburgerInner />
      </HamburgerBox>
    </HamburgerButton>
  )
}

export default HamburgerIcon
