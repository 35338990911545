import React, { useRef, useState, useEffect } from "react"
import tw from "twin.macro"

import Nav from "../../organisms/Nav/Nav"
import Footer from "../../atoms/Footer"
import Hero, { HeroInterface } from "../../organisms/Hero/Hero"
import { FooterInterface } from "../../../interfaces/footer"
import { sm } from "../../eletrons/BreakpointsHelpers"
import Portfolio from "../../organisms/Portfolio/Portfolio"
import { containerTw } from "../../eletrons/BaseStyles"
import About, { AboutInterface } from "../../organisms/About/About"

const styles = {
  footerContainer: [containerTw, tw`w-full`],
  footer: [tw` md:inline-block pb-8 sm:self-end`],
}

interface IndexTemplateInterface {
  hero: HeroInterface
  footer: FooterInterface
  portfolio: string[]
  about: AboutInterface
}

function IndexTemplate({
  hero,
  footer,
  portfolio,
  about,
}: IndexTemplateInterface) {
  const [activeMenu, setActive] = useState(false)
  const activeMenuRef = useRef(activeMenu)
  const [isAfterHeader, setIsAfterHeader] = useState(false)
  const isAfterHeaderRef = useRef(isAfterHeader)
  const heroRef = useRef<HTMLHeadingElement>()

  const toggleMenuButtonDesktopVisibility = () => {
    if (!heroRef.current || activeMenuRef.current) return
    // if (window.innerWidth <= sm) {
    //   return
    // }
    const isAfterHeader = heroRef.current.offsetHeight < window.scrollY
    if (isAfterHeader !== isAfterHeaderRef.current) {
      isAfterHeaderRef.current = isAfterHeader
      setIsAfterHeader(isAfterHeader)
    }
  }

  const toggleMenuActive = () => {
    setActive(!activeMenu)
    activeMenuRef.current = !activeMenu
    toggleMenuButtonDesktopVisibility()
  }

  useEffect(() => {
    if (typeof window === "undefined") return
    toggleMenuButtonDesktopVisibility()
    window.addEventListener("scroll", toggleMenuButtonDesktopVisibility)
    window.addEventListener("resize", toggleMenuButtonDesktopVisibility)

    return () => {
      window.removeEventListener("scroll", toggleMenuButtonDesktopVisibility)
      window.removeEventListener("resize", toggleMenuButtonDesktopVisibility)
    }
  }, [])

  return (
    <>
      <Nav
        styles={[tw`fixed inset-x-0 top-0`]}
        isAfterHeader={isAfterHeader}
        activeButton={activeMenu}
        onClickButton={toggleMenuActive}
      />
      <Hero {...hero} ref={heroRef} footer={footer} />
      <Portfolio portfolio={portfolio} />
      <About {...about} />
      <div css={styles.footerContainer}>
        <Footer styles={styles.footer} />
      </div>
    </>
  )
}

export default IndexTemplate
