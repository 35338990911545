import styled from "@emotion/styled/macro";
import tw from 'twin.macro';
import { css } from "@emotion/core";
import { red } from "../../eletrons/Colors";

export const InvertedButton = styled.button<{ active?: boolean }>(({ active }) => [
  tw`border-2 px-2 py-1`,
  css`
    position: relative;
    background-color: transparent;
    border-color: transparent;
    transition-property: border-color, opacity;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;

    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 100%;
      transition: .2s ease-in-out right;
      background: ${red.toString()};
      z-index: -1;
    }
  `,
  active && css`
    border-color: ${red.toString()};
    &:before {
      right: 0;
    }
  `,
  css`
    :hover, :focus {
      border-color: ${red.toString()};
      outline-color: ${red.lighten(20).toString()};
    }
  `
])

InvertedButton.defaultProps = {
  type: "button"
}

export const InvertedLink = styled(InvertedButton)`
&:hover, &:active, &:focus {
  border-color: ${red.toString()};
  &:before {
    right: 0;
  }
}
`.withComponent("a");

InvertedLink.defaultProps = {
}
