import React from "react"
import tw from "twin.macro"

const paragraphStyle = tw`mb-8`

function Paragraphs({ content }: { content?: string[] }) {
  return (
    <>
      {content?.map((p) => (
        <p
          css={paragraphStyle}
          key={p}
          dangerouslySetInnerHTML={{ __html: p }}
        />
      ))}
    </>
  )
}

export default Paragraphs
