import React from "react"
import tw from "twin.macro"
import BackgroundImage from "gatsby-background-image"
import styled from "@emotion/styled/macro"
import { css } from "@emotion/core"

import { VideosGridQuery } from "../../../graphql-types"
import { gray } from "../../eletrons/Colors"

type Video = VideosGridQuery["allVideosJson"]["nodes"][0]

const Image = styled(BackgroundImage)(() => [
  tw`inset-0 ease-in-out duration-100 transition-transform`,
  css`
    position: absolute !important;
    z-index: -10 !important;
  `,
])

const BgFilter = styled.div(() => [
  tw`absolute inset-0 transition-opacity ease-in-out duration-100`,
  css`
    background-color: ${gray.toString()};
    z-index: -9;
    opacity: 0.7;
  `,
])

const LinkContainer = styled.a`
  overflow: hidden;
  &:hover,
  &:focus,
  &:active {
    ${BgFilter} {
      opacity: 0.2;
    }
    ${Image} {
      transform: scale(1.1);
    }
  }
`

function VideoItem({
  file,
  title,
  url,
  areas: { tag },
  styles,
}: Video & { styles: any[] }) {
  return (
    <LinkContainer
      href={url}
      target="__blank"
      css={[
        tw`p-6 flex flex-col items-center justify-center text-center relative`,
        css`
          min-height: 12rem;
          span {
            font-family: Montserrat, sans-serif;
          }
        `,
        styles,
      ]}
    >
      <span>{title}</span>
      <p css={[tw`text-xs`]}>{tag}</p>
      <BgFilter />
      <Image fluid={file.childImageSharp.fluid} />
    </LinkContainer>
  )
}

export default VideoItem
