import { css, SerializedStyles } from "@emotion/core";

export const sm = 640;
export const md = 720;
export const xl = 1280;

export const smCss = (
  str: TemplateStringsArray
): SerializedStyles => css`
    @media (min-width: ${sm}px) {
      ${str.join("")}
    }
`
export const xlCss = (
  str: TemplateStringsArray
): SerializedStyles => css`
    @media (min-width: ${xl}px) {
      ${str.join("")}
    }
`

export const mediaMaxHeightCss = (height: number) => (
  str: TemplateStringsArray
): SerializedStyles => css`
    @media (max-height: ${height}px) {
      ${str.join("")}
    }
`

export const mediaMinHeightCss = (height: number) => (
  str: TemplateStringsArray
): SerializedStyles => css`
    @media (min-height: ${height}px) {
      ${str.join("")}
    }
`
