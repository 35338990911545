import React, { memo } from "react"
import { motion } from "framer-motion"
import tw from "twin.macro"
import { A } from "./MenuItems.styles"
import { useStaticQuery, graphql } from "gatsby"
import { Query } from "../../../graphql-types"
import { TwinStyle } from "../../../interfaces/styles"

interface MenuItemsInterface {
  ulStyle?: TwinStyle
  liStyle?: TwinStyle
  animations?: {
    container?: any
    item?: any
  }
  onClickItem?: () => void
}

function MenuItems({
  ulStyle,
  liStyle,
  animations,
  onClickItem,
}: MenuItemsInterface) {
  const links: Query = useStaticQuery(graphql`
    query {
      allMenuJson {
        nodes {
          id
          title
          url
        }
      }
    }
  `)

  return (
    <motion.ul variants={animations?.container} css={[tw`text-xl`, ulStyle]}>
      {links?.allMenuJson?.nodes?.map((link) => (
        <motion.li variants={animations?.item} css={tw`flex`} key={link.url}>
          <A
            css={[tw`py-3 w-full`, liStyle]}
            href={link.url}
            onClick={onClickItem}
          >
            {link.id}. <span dangerouslySetInnerHTML={{ __html: link.title }} />
          </A>
        </motion.li>
      ))}
    </motion.ul>
  )
}

export default memo(MenuItems)
