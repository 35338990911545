import React, { useState, useEffect, memo } from "react"
import { Video, Background } from "./VideoBackground.styles"
import { md } from "../../eletrons/BreakpointsHelpers"

function VideoBackground({
  src,
  srcMobile,
  placeholder,
  animation,
}: {
  src: string
  srcMobile: string
  placeholder: string
  play?: boolean
  animation: any
}) {
  const [videoSrc, setVideoSrc] = useState<string>()

  useEffect(() => {
    if (typeof window === "undefined") return

    setVideoSrc(window.innerWidth > md ? src : srcMobile)
  }, [])

  return (
    <Background variants={animation} aria-hidden="true">
      <Video placeholder={placeholder} playsInline autoPlay muted loop>
        {videoSrc && <source src={videoSrc} type="video/mp4" />}
      </Video>
    </Background>
  )
}

export default memo(VideoBackground)
