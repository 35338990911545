import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import tw from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import { InvertedLink } from "./InvertedButton/InvertedButton"
import { Query } from "../../graphql-types"

function SocialList() {
  const query: Query = useStaticQuery(graphql`
    query {
      allSocialsJson {
        nodes {
          id
          icon
          label
          url
        }
      }
    }
  `)

  return (
    <>
      {query?.allSocialsJson?.nodes?.map(({ id, icon, label, url }) => (
        <InvertedLink
          href={url}
          target="__blank"
          key={id}
          css={tw`p-2 flex justify-center`}
        >
          <FontAwesomeIcon
            icon={["fab", icon as IconName]}
            width="0.625em"
            title={label}
            css={tw`fill-current`}
          />
        </InvertedLink>
      ))}
    </>
  )
}

export default SocialList
