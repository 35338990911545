import styled from "@emotion/styled/macro"
import tw from "twin.macro"
import { css } from "@emotion/core"
import { red } from "../../eletrons/Colors"

export const BorderButton = styled.button<{ active?: boolean }>(
  ({ active }) => [
    tw`p-2 px-4 inline-block border-2 border-white border-solid`,
    css`
      position: relative;
      transition: 0.2s ease-in-out background-color,
        0.2s ease-in-out border-color;
      &:before {
        z-index: -50;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 100%;
        transition: 0.2s ease-in-out right, 0.2s ease-in-out background-color;
        background-color: ${red.toString()};
      }

      &:hover,
      &:focus,
      &:active {
        border-color: ${red.toString()};
      }

      &:focus,
      &:active {
        outline-color: ${red.lighten(20).toString()};
      }
    `,
    active &&
      css`
        &::before {
          right: 0%;
        }
      `,
  ]
)

BorderButton.defaultProps = {
  type: "button",
}

export const BorderLink = styled(BorderButton)`
  &:hover,
  &:focus,
  &:active {
    border-color: ${red.toString()};
    &::before {
      right: 0%;
    }
  }
`.withComponent("a")

BorderLink.defaultProps = {}
