import React, { SVGProps } from "react"

type Orientation = "right" | "down" | "left" | "up"

const getRotation = (orientation: Orientation): number => {
  switch (orientation) {
    case "up":
      return -90
    case "down":
      return 90
    case "left":
      return 180
    default:
      return 0
  }
}

function Arrow({
  title = "Seta",
  fill = "#fff",
  orientation = "right",
  ...props
}: { title?: string; fill?: string; orientation?: Orientation } & Partial<
  SVGProps<SVGSVGElement>
>) {
  const rotation = `${getRotation(orientation)}deg`
  return (
    <svg
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{title}</title>
      <path
        d="M28.08 10.67L25.92 12.83L36.0945 23H6V26H36.0945L25.9215 36.17L28.0815 38.33L40.8315 25.58L41.8605 24.5L40.83 23.42L28.08 10.67V10.67Z"
        style={{
          transform: `rotate(${rotation})`,
          transformOrigin: "center",
        }}
        fill={fill}
      />
    </svg>
  )
}

export default Arrow
