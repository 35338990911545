import React, { memo } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import tw from "twin.macro"
import { Query } from "../../../graphql-types"
import Logo from "../../atoms/SvgImages/logo"
import HamburgerIcon from "../../atoms/HamburgerIcon/HamburgerIcon"
import { css } from "@emotion/core"
import { smCss } from "../../eletrons/BreakpointsHelpers"
import NavContent, { NavContentInterface } from "./NavContent"
import { AnimatePresence } from "framer-motion"
import { TwinStyled } from "../../../interfaces/styles"

const styles = {
  content: tw`fixed top-0 inset-x-0 z-40`,
}

interface NavInterface extends NavContentInterface, TwinStyled {
  isAfterHeader?: boolean
  activeButton?: boolean
  onClickButton?: () => void
}

const MemoContent = memo(
  ({
    activeButton,
    onClickLink,
  }: {
    activeButton: boolean
    onClickLink: () => void
  }) => (
    <AnimatePresence>
      {activeButton && (
        <NavContent onClickLink={onClickLink} styles={styles.content} />
      )}
    </AnimatePresence>
  )
)

function Nav({
  styles,
  isAfterHeader,
  activeButton,
  onClickButton,
}: NavInterface) {
  const { site }: Query = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <>
      <div
        css={[
          tw`flex flex-row items-center justify-between p-6 pt-2 pr-2 pb-0 sm:p-6 sm:px-12 z-50 transition-colors duration-100 ease-in-out`,
          css``,
          isAfterHeader &&
            css`
              background: rgb(0, 0, 0);
              background: linear-gradient(
                180deg,
                rgba(24, 24, 24, 0.9) 0%,
                rgba(24, 24, 24, 0.4) 60%,
                rgba(24, 24, 24, 0) 100%
              );
            `,
          styles,
        ]}
      >
        <Link to="/">
          <Logo
            css={[
              css`
                height: 28px;
              `,
              smCss`height: 45px;`,
            ]}
            title={site.siteMetadata.title}
          />
        </Link>
        <HamburgerIcon
          active={activeButton}
          onClick={onClickButton}
          styles={[!isAfterHeader && tw`sm:hidden`]}
        />
      </div>
      <MemoContent activeButton={activeButton} onClickLink={onClickButton} />
    </>
  )
}

export default memo(Nav)
